// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-templates-cfp-index-tsx": () => import("./../../../src/templates/cfp/index.tsx" /* webpackChunkName: "component---src-templates-cfp-index-tsx" */),
  "component---src-templates-contacts-index-tsx": () => import("./../../../src/templates/contacts/index.tsx" /* webpackChunkName: "component---src-templates-contacts-index-tsx" */),
  "component---src-templates-event-index-tsx": () => import("./../../../src/templates/event/index.tsx" /* webpackChunkName: "component---src-templates-event-index-tsx" */),
  "component---src-templates-events-index-tsx": () => import("./../../../src/templates/events/index.tsx" /* webpackChunkName: "component---src-templates-events-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-qna-index-tsx": () => import("./../../../src/templates/qna/index.tsx" /* webpackChunkName: "component---src-templates-qna-index-tsx" */),
  "component---src-templates-speaker-index-tsx": () => import("./../../../src/templates/speaker/index.tsx" /* webpackChunkName: "component---src-templates-speaker-index-tsx" */),
  "component---src-templates-speakers-index-tsx": () => import("./../../../src/templates/speakers/index.tsx" /* webpackChunkName: "component---src-templates-speakers-index-tsx" */),
  "component---src-templates-talk-index-tsx": () => import("./../../../src/templates/talk/index.tsx" /* webpackChunkName: "component---src-templates-talk-index-tsx" */)
}

